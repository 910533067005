import * as React from "react"


// markup
const NotFoundPage = () => {
  return (
    <div>
      <title>Not found</title>
      <h1>Page not found</h1>
      <p>Sorry, we couldn’t find what you were looking for.</p>
    </div>
  )
}

export default NotFoundPage
